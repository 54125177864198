import React from "react";
// import Lottie from 'lottie-react';
// import loadingAnimation from '../../Animations/loadingAnimation.json';
import { ThreeDots } from "react-loader-spinner";

const Loader = () => {

    return (
        <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center z-50'>
            <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#877D6F"
                ariaLabel="revolving-dot-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

};

export default Loader;