import { GiKnifeFork } from "react-icons/gi";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { IoWifi } from "react-icons/io5";
import { GrUserManager } from "react-icons/gr";
import { LuChefHat } from "react-icons/lu";
import { FaSwimmingPool } from "react-icons/fa";
import { GiPowerGenerator } from "react-icons/gi";
import { FaPeopleRoof } from "react-icons/fa6";
import { PiBathtubBold } from "react-icons/pi";
import { PiElevatorFill } from "react-icons/pi";

export const API_URL = 'https://sohamvillas.com/web-backend';

export const VillaImages = {

    "VILLA ARCADIA": [
        { src: "../assets/Villa_Arcadia/1.webp" },
        { src: "../assets/Villa_Arcadia/2.webp" },
        { src: "../assets/Villa_Arcadia/3.webp" },
        { src: "../assets/Villa_Arcadia/4.webp" },
        { src: "../assets/Villa_Arcadia/5.webp" },
        { src: "../assets/Villa_Arcadia/6.webp" },
        { src: "../assets/Villa_Arcadia/7.webp" },
        { src: "../assets/Villa_Arcadia/8.webp" },
        { src: "../assets/Villa_Arcadia/9.webp" },
        { src: "../assets/Villa_Arcadia/10.webp" },
        { src: "../assets/Villa_Arcadia/11.webp" },
        { src: "../assets/Villa_Arcadia/12.webp" },
    ],
    "VILLA SERENITY": [
        { src: "../assets/Villa_Serenity/1.webp" },
        { src: "../assets/Villa_Serenity/2.webp" },
        { src: "../assets/Villa_Serenity/3.webp" },
        { src: "../assets/Villa_Serenity/4.webp" },
        { src: "../assets/Villa_Serenity/5.webp" },
        { src: "../assets/Villa_Serenity/6.webp" },
        { src: "../assets/Villa_Serenity/7.webp" },
        { src: "../assets/Villa_Serenity/8.webp" },
        { src: "../assets/Villa_Serenity/9.webp" },
        { src: "../assets/Villa_Serenity/10.webp" },
        { src: "../assets/Villa_Serenity/11.webp" },
        { src: "../assets/Villa_Serenity/12.webp" },
    ],
    "HOUSE OF BASHŌ": [
        { src: "../assets/House_of_Basho/1.webp" },
        { src: "../assets/House_of_Basho/2.webp" },
        { src: "../assets/House_of_Basho/3.webp" },
        { src: "../assets/House_of_Basho/4.webp" },
        { src: "../assets/House_of_Basho/5.webp" },
        { src: "../assets/House_of_Basho/6.webp" },
        { src: "../assets/House_of_Basho/7.webp" },
        { src: "../assets/House_of_Basho/8.webp" },
        { src: "../assets/House_of_Basho/9.webp" },
        { src: "../assets/House_of_Basho/10.webp" },
        { src: "../assets/House_of_Basho/11.webp" },
        { src: "../assets/House_of_Basho/12.webp" },
        { src: "../assets/House_of_Basho/13.webp" },
        { src: "../assets/House_of_Basho/14.webp" },
    ],
    "VILLA CELEBRATION": [
        { src: "../assets/Villa_Celebration/2.webp" },
        { src: "../assets/Villa_Celebration/3.webp" },
        { src: "../assets/Villa_Celebration/4.webp" },
        { src: "../assets/Villa_Celebration/5.webp" },
        { src: "../assets/Villa_Celebration/6.webp" },
        { src: "../assets/Villa_Celebration/7.webp" },
        { src: "../assets/Villa_Celebration/9.webp" },
        { src: "../assets/Villa_Celebration/10.webp" },
        { src: "../assets/Villa_Celebration/11.webp" },
        { src: "../assets/Villa_Celebration/12.webp" },
    ],
    "VILLA LUXO": [
        { src: "../assets/Villa_Luxo/2.webp" },
        { src: "../assets/Villa_Luxo/3.webp" },
        { src: "../assets/Villa_Luxo/4.webp" },
        { src: "../assets/Villa_Luxo/5.webp" },
        { src: "../assets/Villa_Luxo/6.webp" },
        { src: "../assets/Villa_Luxo/7.webp" },
        { src: "../assets/Villa_Luxo/8.webp" },
        { src: "../assets/Villa_Luxo/9.webp" },
        { src: "../assets/Villa_Luxo/1.webp" },
        { src: "../assets/Villa_Luxo/10.webp" },
        { src: "../assets/Villa_Luxo/11.webp" },
        { src: "../assets/Villa_Luxo/12.webp" },
        { src: "../assets/Villa_Luxo/13.webp" },
        { src: "../assets/Villa_Luxo/14.webp" },
        { src: "../assets/Villa_Luxo/15.webp" },
    ],
    "VILLA CHAPORA": [
        { src: "../assets/Villa_Chapora/2.webp" },
        { src: "../assets/Villa_Chapora/3.webp" },
        { src: "../assets/Villa_Chapora/4.webp" },
        { src: "../assets/Villa_Chapora/5.webp" },
        { src: "../assets/Villa_Chapora/6.webp" },
        { src: "../assets/Villa_Chapora/7.webp" },
        { src: "../assets/Villa_Chapora/1.webp" },
        { src: "../assets/Villa_Chapora/8.webp" },
    ],
    "VILLA MANDOVI": [
        { src: "../assets/Villa_Mandovi/4.webp" },
        { src: "../assets/Villa_Mandovi/5.webp" },
        { src: "../assets/Villa_Mandovi/2.webp" },
        { src: "../assets/Villa_Mandovi/3.webp" },
        { src: "../assets/Villa_Mandovi/6.webp" },
        { src: "../assets/Villa_Mandovi/1.webp" },
        { src: "../assets/Villa_Mandovi/7.webp" },
        { src: "../assets/Villa_Mandovi/8.webp" },
    ],
    "VILLA VERISSIMO": [
        { src: "../assets/Villa_Versimo/1.webp" },
        { src: "../assets/Villa_Versimo/2.webp" },
        { src: "../assets/Villa_Versimo/3.webp" },
        { src: "../assets/Villa_Versimo/4.webp" },
        { src: "../assets/Villa_Versimo/5.webp" },
        { src: "../assets/Villa_Versimo/6.webp" },
        { src: "../assets/Villa_Versimo/7.webp" },
        { src: "../assets/Villa_Versimo/8.webp" },
        { src: "../assets/Villa_Versimo/9.webp" },
        { src: "../assets/Villa_Versimo/10.webp" },
        { src: "../assets/Villa_Versimo/11.webp" },
        { src: "../assets/Villa_Versimo/12.webp" },
        { src: "../assets/Villa_Versimo/13.webp" },
        { src: "../assets/Villa_Versimo/14.webp" },
        { src: "../assets/Villa_Versimo/15.webp" },
    ],
    "VILLA SAL": [
        { src: "../assets/Villa_Sal/1.webp" },
        { src: "../assets/Villa_Sal/2.webp" },
        { src: "../assets/Villa_Sal/3.webp" },
        { src: "../assets/Villa_Sal/4.webp" },
        { src: "../assets/Villa_Sal/5.webp" },
        { src: "../assets/Villa_Sal/6.webp" },
        { src: "../assets/Villa_Sal/7.webp" },
        { src: "../assets/Villa_Sal/8.webp" },
        { src: "../assets/Villa_Sal/9.webp" },
        { src: "../assets/Villa_Sal/10.webp" },
        { src: "../assets/Villa_Sal/11.webp" },
        { src: "../assets/Villa_Sal/12.webp" },
    ],
    "VILLA ZUARI": [
        { src: "../assets/Villa_Zuari/1.webp" },
        { src: "../assets/Villa_Zuari/2.webp" },
        { src: "../assets/Villa_Zuari/3.webp" },
        { src: "../assets/Villa_Zuari/4.webp" },
        { src: "../assets/Villa_Zuari/5.webp" },
        { src: "../assets/Villa_Zuari/6.webp" },
        { src: "../assets/Villa_Zuari/7.webp" },
        { src: "../assets/Villa_Zuari/8.webp" },
        { src: "../assets/Villa_Zuari/9.webp" },
    ],
    "VILLA VERA G-7": [
        { src: "../assets/AQUA_GRANDIOSA_201/AQ1.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ2.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ3.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ4.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ5.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ6.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ7.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ8.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ9.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ10.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ11.webp" },
        { src: "../assets/AQUA_GRANDIOSA_201/AQ12.webp" },
    ],
    "VISION GREENS G-1C": [
        { src: "../assets/VISION_GREENS_G-1C/f1.webp" },
        { src: "../assets/VISION_GREENS_G-1C/f2.webp" },
        { src: "../assets/VISION_GREENS_G-1C/f3.webp" },
        { src: "../assets/VISION_GREENS_G-1C/f4.webp" },
        { src: "../assets/VISION_GREENS_G-1C/f5.webp" },
        { src: "../assets/VISION_GREENS_G-1C/f6.webp" },
        { src: "../assets/VISION_GREENS_G-1C/f7.webp" },
        { src: "../assets/VISION_GREENS_G-1C/f8.webp" },
    ],
    "AQUA GRANDIOSA - 201": [
        { src: "../assets/VILLA_VERA_G-7/VV1.webp" },
        { src: "../assets/VILLA_VERA_G-7/VV2.webp" },
        { src: "../assets/VILLA_VERA_G-7/VV3.webp" },
        { src: "../assets/VILLA_VERA_G-7/VV4.webp" },
        { src: "../assets/VILLA_VERA_G-7/VV5.webp" },
        { src: "../assets/VILLA_VERA_G-7/VV7.webp" },
        { src: "../assets/VILLA_VERA_G-7/VV8.webp" },
    ],
}

export const villaAmenities = {
    "VILLA ARCADIA": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <FaPeopleRoof />, text: 'Rooftop Lounge' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
        { icon: <PiElevatorFill />, text: 'Private Elevator' },

    ],
    "VILLA SERENITY": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <FaPeopleRoof />, text: 'Rooftop Lounge' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
        // { icon: <PiElevatorFill />, text: 'Private Elevator' },
    ],
    "HOUSE OF BASHŌ": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
    ],
    "VILLA CELEBRATION": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <FaPeopleRoof />, text: 'Rooftop Lounge' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
    ],
    "VILLA LUXO": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA CHAPORA": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA MANDOVI": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA VERISSIMO": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA SAL": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA ZUARI": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA VERA G-7": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Basic Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Common Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VISION GREENS G-1C": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Basic Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Common Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "AQUA GRANDIOSA - 201": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Basic Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Common Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
};

export const nearbyServices = {
    "VILLA ARCADIA": [
        { name: 'Baga Beach', rating: 5, link: 'https://maps.app.goo.gl/XNmUDhsghqqJMTh59' },
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA SERENITY": [
        { name: 'Baga Beach', rating: 5, link: 'https://maps.app.goo.gl/XNmUDhsghqqJMTh59' },
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "HOUSE OF BASHŌ": [
        { name: 'Club Cabana', rating: 5, link: 'https://maps.app.goo.gl/JJwXhwV6nCvhqW1d6' },
        { name: 'Hammerz Club', rating: 5, link: 'https://maps.app.goo.gl/YGmhCaQjkoxu9fPh9' },
        { name: 'Anjuna Beach', rating: 5, link: 'https://maps.app.goo.gl/AojvDzUenbG6oiUF9' }
    ],
    "VILLA CELEBRATION": [
        { name: 'Baga Beach', rating: 5, link: 'https://maps.app.goo.gl/XNmUDhsghqqJMTh59' },
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: ' Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA LUXO": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Anjuna Beach', rating: 5, link: 'https://maps.app.goo.gl/AojvDzUenbG6oiUF9' }
    ],
    "VILLA CHAPORA": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA MANDOVI": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA VERISSIMO": [
        { name: 'Soro Club', rating: 5 },
        { name: 'Ozran Beach', rating: 5 },
    ],
    "VILLA SAL": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA ZUARI": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA VERA G-7": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VISION GREENS G-1C": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "AQUA GRANDIOSA - 201": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],

}

export const SafetyAndHygiene = {
    "VILLA ARCADIA": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
        { src: "../assets/protection_icon.webp", text: "Smoke Detectors" }
    ],
    "VILLA SERENITY": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
        { src: "../assets/protection_icon.webp", text: "Smoke Detectors" }
    ],
    "HOUSE OF BASHŌ": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
    ],
    "VILLA CELEBRATION": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
        { src: "../assets/protection_icon.webp", text: "Smoke Detectors" }
    ],
    "VILLA LUXO": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
    ],
    "VILLA CHAPORA": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
    ],
    "VILLA MANDOVI": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
    ],
    "VILLA VERISSIMO": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
    ],
    "VILLA SAL": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
    ],
    "VILLA ZUARI": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
    ],
    "VILLA VERA G-7": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
        { src: "../assets/protection_icon.webp", text: "Smoke Detectors" }
    ],
    "VISION GREENS G-1C": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
        { src: "../assets/protection_icon.webp", text: "Smoke Detectors" }
    ],
    "AQUA GRANDIOSA - 201": [
        { src: "../assets/protection_icon.webp", text: "Daily Cleaning" },
        { src: "../assets/protection_icon.webp", text: "Fire Extinguishers" },
        { src: "../assets/protection_icon.webp", text: "Disinfections and Sterilizations" },
        { src: "../assets/protection_icon.webp", text: "Smoke Detectors" }
    ],

}